import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"

import {seo, site} from "../../theme"

const AboutPage = () => (
  <Layout>
    <SEO title={seo.about.title} />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0" />
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">
            About {site.title}
            </h1>
            <ShortLine />

            <p>Hi, and welcome to Faroese Online! My name is Bjarnfríð (pron. byarnfreeth) from the Faroe islands, and I’m passionate about sharing my language with new learners.</p>

<p>This site is all about 3 things:</p>

<ul>
<li><Link to="/topic/grammar/">Faroese Grammar</Link></li>
<li><Link to="/topic/phrases/">Faroese Phrases</Link></li>
<li><Link to="/topic/vocabulary/">Faroese Vocabulary</Link></li>
</ul>

<p>I encourage you to go through the archive on my site, learn Faroese bit by bit, and <Link to="/contact/">get in contact</Link> if you have any questions.</p>

<p><strong>Góða eydnu!</strong></p>
            
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
